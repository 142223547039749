<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="appstore"></a-icon>
          <span>域名</span>
        </a-space>
      </template>
      <span>域名包含注册商、注册时间、到期时间、DNS 等。</span>
    </a-page-header>

    <a-card :bordered="false">
      <domain-table
        :refresh="refresh"
        :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
      ></domain-table>
    </a-card>
  </div>
</template>

<script>
import DomainTable from '@/components/table/DomainTable/index.vue'

export default {
  name: 'Domain',
  components: {
    DomainTable
  },
  data () {
    return {
      refresh: false
    }
  }
}
</script>
